import { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { authEndpoint } from "../constants";
import { useWindowEvent } from "~/hooks/useWindowEvent";

interface AuthState {
  accessToken?: string;
  user_id?: string;
}

export const AuthContext = createContext<
  [auth: AuthState, setAuth: (AuthState) => void]
>([{}, () => {}]);

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (auth === undefined) {
    throw new Error(
      `The \`useAuth\` hook must be used inside of a AuthContext.`,
    );
  }

  return auth;
};

// export const AuthProvider = AuthContext.Provider
// export const AuthConsumer = AuthContext.Consumer

/**
 * The initial value of `auth` comes from a prop which gets set in _app.
 * We store that value in state and ignore the prop from then on.
 * The value can be changed by calling `setAuth()` from the context.
 */
export function AuthProvider({ children, initialState = {} }) {
  const router = useRouter();
  const [auth, setAuth] = useState<AuthState>(initialState);

  const tokenRefresh = async () => {
    try {
      let resp = await fetch(`${authEndpoint}/refresh`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
      });
      if (resp.ok) {
        let state: AuthState = await resp.json();
        return setAuth((auth) => ({ ...auth, ...state }));
      }
    } catch (error) {
      console.error(error);
    }
    return setAuth({});
  };

  useEffect(() => {
    auth.user_id && tokenRefresh();
  }, []);

  /**
   * Keep auth synced across browser tabs.
   */

  useEffect(
    function persistAuthState() {
      if (auth.user_id) {
        document.cookie = `user_id=${auth.user_id};path=/`;
        localStorage.setItem("user_id", auth.user_id);
      } else {
        document.cookie =
          "user_id=null;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        localStorage.removeItem("user_id");
      }
    },
    [auth],
  );

  useWindowEvent("storage", (event: StorageEvent) => {
    if (event.key === "user_id" && auth.user_id != event.newValue) {
      setAuth({ user_id: event.newValue });
      router.reload();
    }
  });

  const onLogout = (url, { shallow }) => {
    if (/^\/logout/.test(url)) {
      setAuth({});
    }
  };

  useEffect(() => {
    router.events.on("routeChangeStart", onLogout);
    return () => router.events.off("routeChangeStart", onLogout);
  }, [router]);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
}
