import App from "next/app";
import type { AppInitialProps, AppProps, AppContext } from "next/app";
import Head from "next/head";

import { AuthProvider } from "~/providers/auth";
import { UserProvider } from "~/providers/user";

import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import { Provider as ToastProvider } from "@radix-ui/react-toast";

import cookies from "next-cookies";

import "~/styles/tailwind.css";
import "~/styles/global.css";
import "~/styles/prosemirror.css";

type CustomAppProps = {
  auth: Record<string, any> | null;
};

/**
 * TODO: figure out customApp lifecycle (render -> getInitialProps -> render).
 * https://nextjs.org/docs/advanced-features/custom-app
 */
export default class CustomApp extends App<
  any,
  AppInitialProps & AppProps & CustomAppProps
> {
  /**
   * App currently does not support Next.js Data Fetching methods like getStaticProps or getServerSideProps.
   * https://nextjs.org/docs/advanced-features/custom-app#caveats
   */
  static async getInitialProps(
    appContext: AppContext,
  ): Promise<AppInitialProps & CustomAppProps> {
    const { user_id, accessToken } = cookies(appContext.ctx);
    const auth = { user_id, accessToken };
    /**
     * When you add getInitialProps in your custom app, you must import App from "next/app",
     * call App.getInitialProps(appContext) inside getInitialProps and merge the returned object into the return value.
     */
    const props = await App.getInitialProps(appContext);
    return {
      auth,
      ...props,
    };
  }

  render() {
    const { Component, pageProps, auth } = this.props;
    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width,minimum-scale=1,initial-scale=1,viewport-fit=cover"
          />
          <meta name="color-scheme" content="light" />
          <meta name="theme-color" content="#ffffff" />
          <link rel="icon" href="/favicon.ico" />
          <link
            rel="search"
            type="application/opensearchdescription+xml"
            href="/opensearch.xml"
          />
        </Head>
        <ToastProvider>
          <TooltipProvider delayDuration={500} skipDelayDuration={0}>
            <AuthProvider initialState={auth}>
              <Component {...pageProps} />
            </AuthProvider>
          </TooltipProvider>
        </ToastProvider>
      </>
    );
  }
}
